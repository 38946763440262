import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Box from 'shopper/components/Box';
import SpriteSheet from 'shopper/components/SpriteSheet';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import CarouselBox from 'components/CarouselBox';

import { sendEvent } from 'lib/gtag';
import { toBlogTagLink } from 'lib/links';

const CategoriesCarousel = ({ categories, gaEventCategory }) => {
  const onTagClick = (categoryName) =>
    sendEvent({
      category: gaEventCategory,
      action: 'filter_tag',
      value: categoryName,
    });

  return (
    <Box className="mb-6 px-2 md:px-4">
      <CarouselBox
        arrowsProps={{
          className: 'hidden md:flex',
          offsetX: { left: '-left-1.5', right: '-right-1.5' },
          'data-test-selector': 'slide-arrow',
        }}
        carouselProps={{
          className: 'w-full md:w-[96%] ml-0',
        }}
        itemProps={{
          breakpoints: {
            md: {
              slidesPerView: 5,
              itemSize: 90,
            },
            lg: {
              slidesPerView: 5,
              itemSize: 85,
            },
            xl: {
              slidesPerView: 7,
              itemSize: 88,
            },
          },
          slidesPerView: 2,
          itemSize: 75,
          snapMode: 'snap-center',
        }}
        list={categories}
        render={({ categoryId, categoryName, categorySlug, categoryIcon }) => (
          <NextLink
            key={categoryId}
            href={toBlogTagLink(categorySlug)}
            prefetch={false}
            passHref
          >
            <Anchor
              aria-label={`ir para a categoria ${categoryName}`}
              data-test-selector="category-carousel-item"
              onClick={() => onTagClick(categoryName)}
            >
              <div className="flex flex-col items-center justify-center py-4">
                <div className="mb-2 flex size-12 items-center justify-center rounded-full bg-neutral-high-200 dark:bg-neutral-low-200">
                  <SpriteSheet
                    className="size-6"
                    icon={categoryIcon.replace('.svg', '')}
                    sheet="blog-categories-carousel"
                    aria-hidden
                  />
                </div>
                <Text className="whitespace-nowrap text-center" size="size2">
                  {categoryName}
                </Text>
              </div>
            </Anchor>
          </NextLink>
        )}
        showArrows
      />
    </Box>
  );
};

CategoriesCarousel.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categoryName: PropTypes.string.isRequired,
      categorySlug: PropTypes.string.isRequired,
      categoryIcon: PropTypes.string.isRequired,
    })
  ).isRequired,
  gaEventCategory: PropTypes.string.isRequired,
};

export default CategoriesCarousel;
