import { createContext, useContext, useMemo } from 'react';

import useExperimentVariant from 'hooks/useExperimentVariant';
import useTopScrollPosition from 'hooks/useTopScrollPosition';

import { isBrowser } from 'lib/utils';

import LINKS from 'constants/links';

const TOPBAR_MAIN_HEIGHT = {
  DESKTOP: 74,
  MOBILE: 72,
};
const TOPBAR_AD_HEIGHT = {
  DESKTOP: 40,
  MOBILE: 64,
};
const TOPBAR_BOTTOM_HEIGHT = {
  DESKTOP: 43,
  MOBILE: 53,
};

const isMobileTopbarBottomActive = () => {
  const pathsThatTopbarBottomIsActive = [
    LINKS.HOME,
    LINKS.TREND_OFFERS,
    LINKS.RECENT_OFFERS,
    LINKS.LOW_PRICE_OFFERS,
  ];

  return pathsThatTopbarBottomIsActive.includes(
    isBrowser() ? window.location.pathname : ''
  );
};

const getDesktopTopbarHeight = ({ isTopbarAdActive, isTopScrollPosition }) => {
  const topbarMainHeight = TOPBAR_MAIN_HEIGHT.DESKTOP;
  const topbarAdHeight =
    isTopbarAdActive && isTopScrollPosition ? TOPBAR_AD_HEIGHT.DESKTOP : 0;

  return topbarMainHeight + topbarAdHeight + TOPBAR_BOTTOM_HEIGHT.DESKTOP;
};

const getMobileTopbarHeight = ({
  doesTimelineNeedsExternalNavigation,
  isTopbarAdActive,
  isTopScrollPosition,
}) => {
  const topbarMainHeight = TOPBAR_MAIN_HEIGHT.MOBILE;
  const topbarAdHeight =
    isTopbarAdActive && isTopScrollPosition ? TOPBAR_AD_HEIGHT.MOBILE : 0;
  const topbarBottomHeight =
    doesTimelineNeedsExternalNavigation && isMobileTopbarBottomActive()
      ? TOPBAR_BOTTOM_HEIGHT.MOBILE
      : 0;

  return topbarMainHeight + topbarAdHeight + topbarBottomHeight;
};

const TopbarContext = createContext({
  isTopbarActive: true,
  topbarDesktop: null,
  topbarMobile: null,
});

const TopbarProvider = ({
  children,
  isTopbarAdActive,
  isTopbarActive = true,
}) => {
  const [doesTimelineNeedsExternalNavigation] = useExperimentVariant(
    '00018',
    [true, false, false],
    {
      fallback: { outOfDateValue: true },
    }
  );
  const [isTopScrollPosition] = useTopScrollPosition();

  const value = useMemo(() => {
    const topbarDesktop = {
      constants: {
        adHeight: TOPBAR_AD_HEIGHT.DESKTOP,
        topbarBottomHeight: TOPBAR_BOTTOM_HEIGHT.DESKTOP,
        topbarMainHeight: TOPBAR_MAIN_HEIGHT.DESKTOP,
      },
      isTopbarActive,
      height: getDesktopTopbarHeight({ isTopbarAdActive, isTopScrollPosition }),
    };

    const topbarMobile = {
      height: getMobileTopbarHeight({
        doesTimelineNeedsExternalNavigation,
        isTopbarAdActive,
        isTopScrollPosition,
      }),
    };

    return {
      isTopbarActive,
      topbarDesktop,
      topbarMobile,
    };
  }, [
    doesTimelineNeedsExternalNavigation,
    isTopbarActive,
    isTopbarAdActive,
    isTopScrollPosition,
  ]);

  return (
    <TopbarContext.Provider value={value}>{children}</TopbarContext.Provider>
  );
};

export const useTopbar = () => useContext(TopbarContext);

export default TopbarProvider;
