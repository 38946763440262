import PropTypes from 'prop-types';
import { twJoin, twMerge } from 'tailwind-merge';

import { formatAsCurrency, isNumber } from 'lib/number';

import Heading from '../Heading';
import Text from '../Text';

const primaryInfoSizeStylesMap = {
  size1: 'size2',
  size2: 'size4',
  size3: 'size5',
  large: 'size1',
};

const primaryInfoSignalSizeStylesMap = {
  size1: 'size3',
  size2: 'size5',
  size3: 'size5',
  large: 'size1',
};

const secondaryInfoStylesMap = {
  size1: 'size1',
  size2: 'size1',
  size3: 'size2',
  large: 'size1',
};

const getCurrencySignal = (currency) =>
  currency.substring(0, currency.search(/\s+/));

const getCurrencyValue = (currency) =>
  currency.substring(currency.search(/\s+/) + 1, currency.length);

const Price = ({
  className,
  currency = 'BRL',
  finished = false,
  locale = 'pt-BR',
  primaryInfo,
  secondaryInfo,
  size = 'size2',
  ...rest
}) => {
  const primaryInfoSize = primaryInfoSizeStylesMap[size];
  const primaryInfoSignalSize = primaryInfoSignalSizeStylesMap[size];
  const secondaryInfoSize = secondaryInfoStylesMap[size];
  const [currPrimaryInfo, currSecondaryInfo] = [primaryInfo, secondaryInfo].map(
    (info) =>
      isNumber(info)
        ? formatAsCurrency({ number: info, currency, locale })
        : info
  );

  return (
    <div
      className={twMerge('flex w-min flex-col items-start', className)}
      {...rest}
    >
      {currSecondaryInfo && (
        <Text
          as="span"
          className={twJoin(
            'whitespace-nowrap',
            secondaryInfo && 'text-neutral-low-200 dark:text-neutral-high-400',
            secondaryInfo && isNumber(secondaryInfo) && 'line-through'
          )}
          size={secondaryInfoSize}
        >
          {currSecondaryInfo}
        </Text>
      )}
      {isNumber(primaryInfo) ? (
        <div className="items-center">
          <Heading
            as="span"
            className={twJoin(
              'pr-1',
              finished
                ? 'text-neutral-low-400 dark:text-neutral-high-200'
                : 'text-primary-400 dark:text-primary-200'
            )}
            size={primaryInfoSignalSize}
          >
            {getCurrencySignal(currPrimaryInfo)}
          </Heading>
          <Heading
            as="span"
            className={twJoin(
              'whitespace-nowrap',
              finished
                ? 'text-neutral-low-400 dark:text-neutral-high-200'
                : 'text-primary-400 dark:text-primary-200'
            )}
            size={primaryInfoSize}
          >
            {getCurrencyValue(currPrimaryInfo)}
          </Heading>
        </div>
      ) : (
        <Heading
          as="span"
          className={twJoin(
            'whitespace-nowrap',
            finished
              ? 'text-neutral-low-400 dark:text-neutral-high-200'
              : 'text-primary-400 dark:text-primary-200'
          )}
          size={primaryInfoSize}
        >
          {currPrimaryInfo}
        </Heading>
      )}
    </div>
  );
};

Price.propTypes = {
  /**
   * Sets primary info. If a number is passed it will be formatted
   * using `currency` and `locale` props, otherwise it is rendered
   * normally as a string
   */
  primaryInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * Sets secondary info. If a number is passed it will be formatted
   * using `currency` and `locale` props, otherwise it is rendered
   * normally as a string
   */
  secondaryInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Sets primary info color to gray
   */
  finished: PropTypes.bool,
  /**
   * Renders a text with a determined size
   */
  size: PropTypes.oneOf(['size1', 'size2', 'size3', 'large']),
  /**
   * Currency used to format `primaryInfo` and `secondaryInfo` props.
   * A Intl.NumberFormat constructor option `currency`
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  currency: PropTypes.string,
  /**
   * Locale used to format `primaryInfo` and `secondaryInfo` props.
   * A string with a BCP 47 language tag
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
   */
  locale: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click
   */
  onClick: PropTypes.func,
};
export default Price;
