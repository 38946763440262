import PropTypes from 'prop-types';
import { Children } from 'react';

import { nonNull } from 'lib/array';

import { isLastItem } from '../../utils/array';
import BreadcrumbItemWrapper from './BreadcrumbItemWrapper';

function Breadcrumb({ children, className, ...rest }) {
  // If there's a `false` children, it is commented
  // and therefore we shouldn't render
  const activeChildrenArr = Array.isArray(children) && nonNull(children);

  return (
    <nav aria-label="breadcrumb" className={className} {...rest}>
      <ol
        className={
          'group flex w-full flex-row flex-nowrap items-center overflow-x-auto py-4 scrollbar-none md:overflow-hidden lg:py-6 lg:pb-4'
        }
      >
        {Array.isArray(children) ? (
          Children.map(activeChildrenArr, (child, i) => (
            <BreadcrumbItemWrapper
              key={i}
              icon={!isLastItem(activeChildrenArr, i)}
            >
              {child}
            </BreadcrumbItemWrapper>
          ))
        ) : (
          <BreadcrumbItemWrapper unique>{children}</BreadcrumbItemWrapper>
        )}
      </ol>
    </nav>
  );
}

Breadcrumb.propTypes = {
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Components to be rendered inside component
   */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
};

export default Breadcrumb;
