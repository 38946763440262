import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { removeFromLocalStorage } from 'lib/localStorage';

import useLocalStorageState from './useLocalStorageState';

import LOCAL_STORAGE from 'constants/localStorage';

const OPTIONS = {
  light: 'light',
  dark: 'dark',
};

const BLACK_FRIDAY_PATH = '/black-friday';

const resetUserThemePreference = () => {
  removeFromLocalStorage(LOCAL_STORAGE.IS_DARK_MODE_ACTIVE);
};

const useTheme = () => {
  const { pathname } = useRouter();
  const [isDarkModeActive, setDarkModeActive] = useLocalStorageState(
    LOCAL_STORAGE.IS_DARK_MODE_ACTIVE,
    false
  );

  const isBlackFridayPage = pathname.includes(BLACK_FRIDAY_PATH);
  const currTheme =
    isDarkModeActive || isBlackFridayPage ? OPTIONS.dark : OPTIONS.light;

  // TODO: implement BroadcastChannel to update theme for all open tabs
  const setTheme = (newTheme) => {
    if (isBlackFridayPage) {
      return;
    }

    document.documentElement.classList = '';
    document.documentElement.classList.add(newTheme);
    setDarkModeActive(newTheme === OPTIONS.dark);
  };

  useEffect(() => {
    document.documentElement.classList = '';

    if (isBlackFridayPage) {
      document.documentElement.classList.add(OPTIONS.dark);
      return;
    }

    document.documentElement.classList.add(OPTIONS.light);
    setTheme(isDarkModeActive ? OPTIONS.dark : OPTIONS.light);
  }, [isBlackFridayPage]);

  const changeThemeToStoredUserPreference = () => {
    if (isBlackFridayPage) {
      return;
    }

    setTheme(isDarkModeActive ? 'dark' : 'light');
  };

  return {
    options: OPTIONS,
    theme: currTheme,
    changeThemeToStoredUserPreference,
    resetUserThemePreference,
    setTheme,
  };
};

export default useTheme;
