import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const LayoutMargin = ({
  className,
  maxWidthOnMobileDevices = false,
  ...rest
}) => (
  <div
    className={twMerge(
      'relative mx-auto my-0 mb-6 w-full lg:w-screen-lg lg:px-2 xl:w-screen-xl',
      maxWidthOnMobileDevices ? 'px-0' : 'px-4',
      className
    )}
    {...rest}
  />
);

LayoutMargin.propTypes = {
  maxWidthOnMobileDevices: PropTypes.bool,
};

export default LayoutMargin;
